<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="ModalRedeemError"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="text-center">
        <img :src="cancelIcon" alt="success" class="mb-4 class-icon-black" />
      </div>
      <div class="text-center mb-4">
        <p class="text-text" v-if="text">{{ text }}</p>
      </div>
      <div class="border-top p-3 text-center btn-confirm-popup bg-black">
        <div @click="hide">ปิด</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import cancel from "@/assets/images/cancel-icon.svg";
export default {
  props: {
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      showModal: false,
      cancelIcon : cancel
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.bg-black{
  background-color: #000;
  color: #fff;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  font-family: "Kanit-Medium" !important;
  color: #000;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black{
  width: 70px;
}
@media (max-width: 767.98px) {
  .text-text {
    font-size: 20px;
  }
}
</style>
