<template>
  <div class="p-3 content-page">
    <label class="ft-weight">จัดการข้อมูลส่วนตัว</label>
    <b-form-group>
      <b-form-radio-group
        v-model="form.request_type_id"
        :options="list"
        stacked
        @change="getRequestTypeId"
      ></b-form-radio-group>
    </b-form-group>
    <InputTextArea
      textFloat="ระบุเหตุผลเพิ่มเติม"
      placeholder="ระบุเหตุผลเพิ่มเติม"
      rows="5"
      v-model="form.note"
    />
    <div class="register-bottom fixed-bottom bg-white p-2 shadow">
      <b-button
        class="w-100"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        @click="sendFormPdpa"
        :disabled="disableBtn"
        >ส่งข้อมูล</b-button
      >
    </div>
  </div>
</template>

<script>
import InputTextArea from "@/components/input/InputTextArea";
export default {
  components: {
    InputTextArea
  },
  props: {
    list: {
      required: true,
      type: Array
    },
    form: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selected: null,
      disableBtn: true
    };
  },
  methods: {
    getRequestTypeId(value) {
      if (value) {
        this.disableBtn = false;
      }
    },
    sendFormPdpa() {
      this.$emit("getPdpa", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep label {
  color: #16274a;
  font-size: 15px;
  margin-bottom: 2px;
}
.ft-weight {
  font-weight: 600;
}
.content-page {
  margin-top: 80px;
}
</style>